<template>
  <div :class="$style.introductionContainer" data-luko-tracking="Introduction">
    <RichText :text-params="primary.text" />
  </div>
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'Introduction',

  components: { RichText },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },
}
</script>

<style lang="scss" module>
.introductionContainer {
  color: $gray-1000;
  font-style: italic;
}
</style>
