<template>
  <figure
    :class="[
      [$style.imageContainer],
      { [$style.hasMobileImage]: hasMobileImage },
    ]"
    data-luko-tracking="ArticleImage"
  >
    <PImage :image-object="primary.image" :class="$style.desktopImage" />
    <PImage
      v-if="hasMobileImage"
      :image-object="primary.mobile_image"
      :class="$style.mobileImage"
    />
    <figcaption>
      <RichText :text-params="primary.caption" />
    </figcaption>
  </figure>
</template>

<script>
import PImage from '~/components/PImage'
import RichText from '~/components/RichText'

export default {
  name: 'ArticleImage',

  components: { PImage, RichText },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },

  computed: {
    hasMobileImage() {
      return this.primary.mobile_image?.url
    },
  },
}
</script>

<style lang="scss" module>
.imageContainer {
  width: 100%;

  img {
    width: 100%;
    border-radius: 8px;
  }

  figcaption {
    margin-top: 8px;

    @include typo-sub-text;

    color: $gray-400;
    font-style: italic;
    text-align: center;
    a {
      color: $bluko-500;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.hasMobileImage {
  .desktopImage {
    display: none;
    @include above(small) {
      display: block;
    }
  }

  .mobileImage {
    @include above(small) {
      display: none;
    }
  }
}
</style>
