<template>
  <div :class="$style.buttonContainer" data-luko-tracking="ArticleButton">
    <LkButton
      :class="$style.button"
      is-fluid-below-small
      :href="getFormatedLink(primary.link)"
      :target="primary.link.target"
    >
      {{ primary.text }}
    </LkButton>
  </div>
</template>

<script>
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'ArticleButton',

  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },
}
</script>

<style lang="scss" module>
.buttonContainer {
  margin: 80px auto;

  text-align: center;
}
</style>
