<template>
  <div
    :class="$style.articleDocumentContainer"
    data-luko-tracking="ArticleDocument"
  >
    <Title :class="$style.title" :title-params="primary.title" />
    <RichText :class="$style.leftAddress" :text-params="primary.left_address" />
    <RichText
      :class="$style.rightAddress"
      :text-params="primary.right_address"
    />
    <RichText :class="$style.documentObject" :text-params="primary.object" />
    <RichText :class="$style.documentBody" :text-params="primary.letter_body" />
    <RichText
      :class="$style.bottomRightText"
      :text-params="primary.bottom_right_text"
    />
  </div>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'

export default {
  name: 'ArticleDocument',

  components: { RichText, Title },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },
}
</script>

<style lang="scss" module>
.articleDocumentContainer {
  margin-top: 24px;
  padding: 24px;
  border: 2px solid #eae9e3;
  border-radius: 8px;

  color: $gray-700;

  em {
    color: $gray-400;
  }

  strong {
    color: $gray-1000;
    font-weight: 500;
  }
  @include above(medium) {
    padding: 32px;
  }
}

.title {
  @include typo-title;
  color: $gray-1000;
  text-align: center;
}

.leftAddress {
  margin-top: 16px;
}

.rightAddress {
  margin-top: 16px;

  text-align: right;
}

.documentBody {
  margin-top: 32px;
}

.documentObject {
  margin-top: 32px;
}

.bottomRightText {
  margin-top: 32px;

  text-align: right;
}
</style>
