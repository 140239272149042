// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextBlock__textContainer___1MotF{color:inherit;font-weight:400;font-size:16px;line-height:24px;color:#6b6651}.TextBlock__textContainer___1MotF h3{display:block;margin-top:80px;margin-bottom:-8px;color:inherit;font-weight:400;font-size:20px;line-height:32px;font-weight:500}.TextBlock__textContainer___1MotF h3,.TextBlock__textContainer___1MotF strong{color:#2c2302}.TextBlock__textContainer___1MotF h4{margin-top:64px;color:#6b6651;font-weight:500;text-transform:uppercase}.TextBlock__textContainer___1MotF p{margin-top:24px}.TextBlock__textContainer___1MotF img{width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"textContainer": "TextBlock__textContainer___1MotF"
};
module.exports = ___CSS_LOADER_EXPORT___;
