// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleDocument__articleDocumentContainer___3qmuj{margin-top:24px;padding:24px;border:2px solid #eae9e3;border-radius:8px;color:#6b6651}.ArticleDocument__articleDocumentContainer___3qmuj em{color:#aaa8a1}.ArticleDocument__articleDocumentContainer___3qmuj strong{color:#2c2302;font-weight:500}@media (min-width:960px){.ArticleDocument__articleDocumentContainer___3qmuj{padding:32px}}.ArticleDocument__title___6zXK5{color:inherit;font-weight:500;font-size:28px;line-height:40px;color:#2c2302;text-align:center}.ArticleDocument__leftAddress___F6_bP,.ArticleDocument__rightAddress___qM5Iy{margin-top:16px}.ArticleDocument__rightAddress___qM5Iy{text-align:right}.ArticleDocument__documentBody___2mEoZ,.ArticleDocument__documentObject___24KaI{margin-top:32px}.ArticleDocument__bottomRightText___-M9U2{margin-top:32px;text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"articleDocumentContainer": "ArticleDocument__articleDocumentContainer___3qmuj",
	"title": "ArticleDocument__title___6zXK5",
	"leftAddress": "ArticleDocument__leftAddress___F6_bP",
	"rightAddress": "ArticleDocument__rightAddress___qM5Iy",
	"documentBody": "ArticleDocument__documentBody___2mEoZ",
	"documentObject": "ArticleDocument__documentObject___24KaI",
	"bottomRightText": "ArticleDocument__bottomRightText___-M9U2"
};
module.exports = ___CSS_LOADER_EXPORT___;
