// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TwitterTestimonial__tweet___104up{margin-top:24px}@media (min-width:720px){.TwitterTestimonial__tweet___104up{flex-basis:calc(50% - 24px);flex-grow:1;min-width:350px;margin-left:24px}}@media (min-width:960px){.TwitterTestimonial__tweet___104up{flex-basis:none;flex-grow:0;min-width:0;margin-left:0}.TwitterTestimonial__tweet___104up:first-child{margin-top:0}}.TwitterTestimonial__tweet___104up>.TwitterTestimonial__tweetContent___-Dj-N,.TwitterTestimonial__tweet___104up>.TwitterTestimonial__tweetInfos___2Whuu{display:none}.TwitterTestimonial__tweetLink___2fqmv{display:block;padding:24px;border:2px solid #d5d4cf;border-radius:8px;color:#2c2302;text-decoration:none;transition:border-color .3s}.TwitterTestimonial__tweetLink___2fqmv:hover{border:2px solid #eae9e3}.TwitterTestimonial__tweetLink___2fqmv a{color:#2d50e6;text-decoration:none}.TwitterTestimonial__tweetLink___2fqmv a:hover{text-decoration:underline}.TwitterTestimonial__tweetInfos___2Whuu{display:flex;align-items:center;margin-top:24px}.TwitterTestimonial__tweetInfos___2Whuu svg{width:24px;height:24px}.TwitterTestimonial__tweetInfos___2Whuu .TwitterTestimonial__profilePicture___2v84q{width:32px;height:32px;border-radius:24px;overflow:hidden;background-color:#d6e3fd}.TwitterTestimonial__tweetInfos___2Whuu .TwitterTestimonial__profilePicture___2v84q img{display:block;width:100%;height:100%}.TwitterTestimonial__tweetName___3TgI7{flex-grow:1;margin-left:16px;color:#96948a}.TwitterTestimonial__twitterIcon___bpjoR path{fill:#1da1f2!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"tweet": "TwitterTestimonial__tweet___104up",
	"tweetContent": "TwitterTestimonial__tweetContent___-Dj-N",
	"tweetInfos": "TwitterTestimonial__tweetInfos___2Whuu",
	"tweetLink": "TwitterTestimonial__tweetLink___2fqmv",
	"profilePicture": "TwitterTestimonial__profilePicture___2v84q",
	"tweetName": "TwitterTestimonial__tweetName___3TgI7",
	"twitterIcon": "TwitterTestimonial__twitterIcon___bpjoR"
};
module.exports = ___CSS_LOADER_EXPORT___;
