// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Summary__summaryContainer___3D8h-{width:100%;height:100%}.Summary__stickyContainer___2vmQN{position:-webkit-sticky;position:sticky;top:128px}.Summary__bar___2plDf{position:relative}.Summary__progressBar___Hc8QG{position:absolute;top:0;left:0;width:8px;height:100%;border-top-right-radius:8px;border-bottom-left-radius:8px;overflow:hidden;background-color:#eae9e3}.Summary__progression___14gbi{display:block;width:100%;background-color:#2d50e6;transition:height .3s}.Summary__summaryList___mYEui{padding-left:24px}.Summary__summaryList___mYEui li a{display:block;padding:12px 0;color:#6b6651;text-decoration:none;color:inherit;font-weight:400;font-size:16px;line-height:24px}.Summary__summaryList___mYEui li a:hover{text-decoration:underline}li.Summary__activeTitle___2Nvkd a{color:#2d50e6}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"summaryContainer": "Summary__summaryContainer___3D8h-",
	"stickyContainer": "Summary__stickyContainer___2vmQN",
	"bar": "Summary__bar___2plDf",
	"progressBar": "Summary__progressBar___Hc8QG",
	"progression": "Summary__progression___14gbi",
	"summaryList": "Summary__summaryList___mYEui",
	"activeTitle": "Summary__activeTitle___2Nvkd"
};
module.exports = ___CSS_LOADER_EXPORT___;
