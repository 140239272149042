// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Author__authorContainer___CtYRM{display:flex;margin-bottom:40px}@media (min-width:960px){.Author__authorContainer___CtYRM{margin-bottom:180px}}.Author__image___CpOAl{width:48px;height:48px;border-radius:24px;-o-object-fit:cover;object-fit:cover}.Author__authorInfos___244qI{margin-left:16px}.Author__lastUpdate___20GOI{color:inherit;font-weight:400;font-size:14px;line-height:20px;color:#aaa8a1}.Author__lastUpdate___20GOI span{color:#2c2302}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"authorContainer": "Author__authorContainer___CtYRM",
	"image": "Author__image___CpOAl",
	"authorInfos": "Author__authorInfos___244qI",
	"lastUpdate": "Author__lastUpdate___20GOI"
};
module.exports = ___CSS_LOADER_EXPORT___;
