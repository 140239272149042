<template>
  <Title :id="titleId" :class="$style.title" :title-params="primary.text" data-luko-tracking="SecondTitle" />
</template>

<script>
import Title from '~/components/Title'

export default {
  name: 'SecondTitle',

  components: { Title },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      hasPassed: false,
    }
  },

  computed: {
    titleId() {
      const formatedTitle = this.primary.text[0].text
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },

  mounted() {
    document.addEventListener('scroll', this.debounce(this.storeScroll), {
      passive: true,
    })

    this.storeScroll()
  },

  methods: {
    debounce(fn) {
      let frame
      return (...params) => {
        if (frame) {
          cancelAnimationFrame(frame)
        }

        frame = requestAnimationFrame(() => {
          fn(...params)
        })
      }
    },

    storeScroll() {
      const element = document.getElementById(this.titleId)
      if (element) {
        const offset = window.scrollY - element.offsetTop + 200

        if (offset > 0) this.$emit('title-active', this.primary.text[0].text)
        else this.$emit('title-inactive', this.primary.text[0].text)
      }
    },
  },
}
</script>

<style lang="scss" module>
.title {
  @include typo-large-title;
  padding-top: 128px;

  color: $gray-1000;
  hyphens: auto;
}
</style>
