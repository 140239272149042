<template>
  <section :class="[$style.container]" data-luko-tracking="CardCta">
    <div
      v-for="(item, idx) in items"
      :key="idx"
      :class="[$style.card, { [$style.isOdd]: isOdd }]"
    >
      <PImage
        :image-object="item.image"
        sizes="xs:400px"
        loading="lazy"
        :quality="80"
        :class="[$style.image, { [$style.isOdd]: isOdd }]"
      />
      <div :class="[$style.content, { [$style.isOdd]: isOdd }]">
        <RichText
          :text-params="item.title"
          :class="[$style.title, { [$style.isOdd]: isOdd }]"
        />
        <RichText :text-params="item.description" :class="$style.description" />
        <LkButton
          v-if="item.link && item.link_text"
          :href="getFormatedLink(item.link)"
          :class="$style.button"
          :target="item.link.target"
        >
          {{ item.link_text }}
        </LkButton>
      </div>
    </div>
  </section>
</template>

<script>
import RichText from '~/components/RichText'
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'CardCta',
  components: {
    RichText,
  },
  mixins: [getFormatedLink],
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: this.slice.items.filter((item) => item.image && item.image.url),
    }
  },

  computed: {
    isOdd() {
      return !!(this.items.length % 2)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
  padding-top: 80px;
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 46%;
  min-width: 260px;
  border-radius: 8px;

  background-color: #fff1e9;

  @include below(xsmall) {
    width: 100%;
  }
}

.card.isOdd {
  flex-direction: row;
  width: 100%;
  @include below(xsmall) {
    flex-direction: column;
  }
}

.image {
  width: 100%;
  height: 120px;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  object-position: center center;
}

.image.isOdd {
  width: 25%;
  height: 100%;
  border-radius: 8px 0 0 8px;

  @include below(xsmall) {
    width: 100%;
    height: 120px;
    border-radius: 8px 8px 0 0;
  }
}

.content {
  padding: 24px;

  text-align: center;
}

.content.isOdd {
  width: 75%;

  text-align: left;

  @include below(xsmall) {
    width: 100%;

    text-align: center;
  }
}

.button {
  margin-top: 24px;
  &:hover {
    text-decoration: none !important;
  }
}

.title {
  @include typo-headline-bold;
  margin-bottom: 8px;

  color: $gray-1000;

  strong {
    color: $bluko-500;
  }
}
.title.isOdd {
  margin-bottom: 4px;
  @include below(small) {
    margin-bottom: 8px;
  }
}

.description {
  color: $gray-700;
}
</style>
