<template>
  <div :class="$style.tweetsListContainer" data-luko-tracking="TweetsList">
    <TwitterTestimonial
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :class="$style.tweet"
      :content="item"
    />
  </div>
</template>

<script>
import TwitterTestimonial from '~/components/TwitterTestimonial'

export default {
  name: 'TweetsList',

  components: {
    TwitterTestimonial,
  },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.tweet {
  margin-top: 24px;

  &:first-child {
    margin-top: 16px;
  }
}
</style>
