// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StickyCta__container___3eed7{width:100%;margin-top:40px;padding:24px;border-radius:8px;background-color:#ebf5ff;background-repeat:no-repeat;background-position:100% 100%;background-size:80px 80px}.StickyCta__inner___2mkG5{display:flex}.StickyCta__image___1H4KU{width:48px;height:48px}.StickyCta__description___35Z5d{margin-left:16px;font-size:18px;line-height:26px}.StickyCta__button___2yQNV,.StickyCta__description___35Z5d strong{font-weight:500}.StickyCta__button___2yQNV{color:inherit;font-size:16px;line-height:24px;display:flex;align-items:center;justify-content:center;margin-top:17px;padding:12px;border:1px solid #2d50e6;border-radius:8px;color:#2d50e6;text-decoration:none;transition:background-color .2s ease-in-out}.StickyCta__button___2yQNV:hover{background-color:#d6e3fd}.StickyCta__button___2yQNV:hover .StickyCta__icon___2JKP5{transform:translateX(5px)}.StickyCta__icon___2JKP5{width:14px;height:14px;margin-left:9px;transition:transform .2s ease-in-out}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "StickyCta__container___3eed7",
	"inner": "StickyCta__inner___2mkG5",
	"image": "StickyCta__image___1H4KU",
	"description": "StickyCta__description___35Z5d",
	"button": "StickyCta__button___2yQNV",
	"icon": "StickyCta__icon___2JKP5"
};
module.exports = ___CSS_LOADER_EXPORT___;
