<template>
  <section :class="[$style.container]" data-luko-tracking="StickyCta">
    <div :class="$style.inner">
      <img :src="getImage" :class="$style.image" />
      <RichText
        :text-params="primary.description"
        :class="$style.description"
      />
    </div>
    <a
      v-if="primary.link && primary.link_text"
      :href="getFormatedLink(primary.link)"
      :class="$style.button"
      target="_blank"
    >
      {{ primary.link_text }}
      <ArrowRightIcon :class="$style.icon" />
    </a>
  </section>
</template>

<script>
import ArrowRightIcon from 'LkIcons/ArrowRight.vue'

import RichText from '~/components/RichText'
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'StickyCta',
  components: {
    RichText,
    ArrowRightIcon,
  },
  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },
  computed: {
    getImage() {
      return require(`@/assets/images/advice/sticky-bg-${this.primary.background_image}.svg`)
    },
  },
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
  margin-top: 40px;
  padding: 24px 24px;
  border-radius: 8px;

  background-color: $bluko-50;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 80px 80px;
}
.inner {
  display: flex;
}
.image {
  width: 48px;
  height: 48px;
}
.description {
  margin-left: 16px;

  font-size: 18px;
  line-height: 26px;
  strong {
    font-weight: 500;
  }
}

// .clock {
//   background-image: url('@/assets/images/advice/sticky-bg-clock.png');
// }
// .euro {
//   background-image: url('@/assets/images/advice/sticky-bg-euro.png');
// }
// .lock {
//   background-image: url('@/assets/images/advice/sticky-bg-lock.png');
// }
// .brush {
//   background-image: url('@/assets/images/advice/sticky-bg-brush.png');
// }
// .calendar {
//   background-image: url('@/assets/images/advice/sticky-bg-calendar.png');
// }
.button {
  @include typo-body-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
  padding: 12px 12px;
  border: 1px solid $bluko-500;
  border-radius: 8px;

  color: $bluko-500;
  text-decoration: none;

  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: $bluko-100;
    .icon {
      transform: translateX(5px);
    }
  }
}

.icon {
  width: 14px;
  height: 14px;
  margin-left: 9px;

  transition: transform 200ms ease-in-out;
}
</style>
