// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleLink__link___tVMyO{display:block;margin-top:24px}.ArticleLink__buttonContainer___291H7{padding-top:40px;text-align:center}.ArticleLink__button___3mgSG:hover{text-decoration:none!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"link": "ArticleLink__link___tVMyO",
	"buttonContainer": "ArticleLink__buttonContainer___291H7",
	"button": "ArticleLink__button___3mgSG"
};
module.exports = ___CSS_LOADER_EXPORT___;
