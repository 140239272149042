<template>
  <div
    data-luko-tracking="Highlight"
    :class="[
      $style.highlightContainer,
      { [$style.warning]: type === 'warning' },
      { [$style.danger]: type === 'danger' },
    ]"
    data-aos="zoom-in"
    data-aos-duration="500"
  >
    <RichText :text-params="primary.text" />
  </div>
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'Highlight',

  components: { RichText },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },

  computed: {
    type() {
      return this.primary.type.split(' ')[0].toLowerCase()
    },
  },
}
</script>

<style lang="scss" module>
.highlightContainer {
  margin: 24px 12px 0 12px;
  padding: 24px;
  border-radius: 8px;

  color: $bluko-500;

  background-color: $bluko-50;
  box-shadow: 0px 4px 8px $gray-100;
  a {
    color: inherit;
    text-decoration: underline !important;
  }
}

.danger {
  color: $terracota-400;

  background-color: $terracota-50;
}

.warning {
  color: $orange-400;

  background-color: $orange-50;
}
</style>
