<template>
  <section
    :class="$style.container"
    data-luko-tracking="BannerCta"
    :style="backgroundImage"
  >
    <div :class="$style.text">
      <Title :title-params="primary.title" :class="$style.title" />
      <RichText
        :text-params="primary.description"
        :class="$style.description"
      />
      <LkButton
        v-if="primary.link && primary.link_text"
        :href="getFormatedLink(primary.link)"
        is-small
        :class="$style.button"
        :target="primary.link.target"
      >
        {{ primary.link_text }}
      </LkButton>
    </div>
  </section>
</template>

<script>
import Title from '~/components/Title'
import RichText from '~/components/RichText'
import { getFormatedLink } from '~/mixins/getFormatedLink'

export default {
  name: 'BannerCta',
  components: {
    Title,
    RichText,
  },
  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
  computed: {
    backgroundImage() {
      if (
        !this.slice.primary.background_image ||
        !this.slice.primary.background_image.url
      )
        return {}
      const imgUrl = this.$img.getSizes(
        this.slice.primary.background_image.url,
        {
          sizes: 'xs:350px',
          modifiers: {
            quality: 80,
          },
        }
      )
      return {
        backgroundImage: `url(${imgUrl.src})`,
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  min-height: 176px;
  margin-top: 80px;
  padding: 24px 0 24px 32px;
  border-radius: 8px;

  background-color: #fff1e9;
  background-image: url('@/assets/images/advice/banner-cta-bg.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 260px auto;

  @include below(small) {
    padding: 24px;

    background-image: none;
  }
}

.text {
  max-width: 65%;
  @include below(small) {
    max-width: 100%;
  }
}

.title {
  @include typo-headline-bold;
  color: $bluko-500;
  h3 {
    margin-bottom: 0;
  }
}

.desciprion {
  color: $gray-1000;
}

.button {
  margin-top: 16px;
  &:hover {
    text-decoration: none !important;
  }
}
</style>
