<template>
  <RichText
    :class="$style.textContainer"
    :text-params="primary.text"
    data-luko-tracking="TextBlock"
  />
</template>

<script>
import RichText from '~/components/RichText'

export default {
  name: 'TextBlock',

  components: { RichText },

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },
}
</script>

<style lang="scss" module>
.textContainer {
  @include typo-body;
  color: $gray-700;

  h3 {
    display: block;
    margin-top: 80px;
    margin-bottom: -8px;

    @include typo-headline;
    font-weight: 500;
  }

  strong,
  h3 {
    color: $gray-1000;
  }

  h4 {
    margin-top: 64px;

    color: $gray-700;
    font-weight: 500;
    text-transform: uppercase;
  }

  p {
    margin-top: 24px;
  }
  img {
    width: 100%;
  }
}
</style>
