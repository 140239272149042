var render = function () {
var _obj, _obj$1, _obj$2, _obj$3;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.tableContainer,attrs:{"data-luko-tracking":"ArticleTable"}},[_c('Title',{class:_vm.$style.title,attrs:{"is-headline":"","title-params":_vm.primary.table_title}}),_vm._v(" "),_c('div',{class:_vm.$style.tableWrapper},[_c('table',{class:[
        [_vm.$style.table],
        ( _obj = {}, _obj[_vm.$style.firstCentered] = _vm.primary.first_col_text_centered, _obj ),
        ( _obj$1 = {}, _obj$1[_vm.$style.secondCentered] = _vm.primary.second_col_text_centered, _obj$1 ),
        ( _obj$2 = {}, _obj$2[_vm.$style.thirdCentered] = _vm.primary.third_col_text_centered, _obj$2 ),
        ( _obj$3 = {}, _obj$3[_vm.$style.fourthCentered] = _vm.primary.fourth_col_text_centered, _obj$3 ) ]},[_c('thead',[_c('tr',{class:_vm.$style.tableHeader},[_c('th',[_vm._v("\n            "+_vm._s(_vm.primary.first_col_title)+"\n          ")]),_vm._v(" "),_c('th',[_vm._v("\n            "+_vm._s(_vm.primary.second_col_title)+"\n          ")]),_vm._v(" "),_c('th',[_vm._v("\n            "+_vm._s(_vm.primary.third_col_title)+"\n          ")]),_vm._v(" "),_c('th',[_vm._v("\n            "+_vm._s(_vm.primary.fourth_col_title)+"\n          ")])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index,class:_vm.$style.tableRow},_vm._l((item),function(col,colIndex){
      var _obj;
return _c('td',{key:colIndex},[(col[0] && col[0].text === 'true')?_c('CheckIcon',{staticClass:"icon-mint-500",class:_vm.$style.icon,attrs:{"height":"16px"}}):(col[0] && col[0].text === 'false')?_c('CrossIcon',{staticClass:"icon-terracota-500",class:_vm.$style.icon,attrs:{"height":"12px"}}):_c('RichText',{class:[
                [_vm.$style.richtext],
                ( _obj = {}, _obj[_vm.$style.firstCol] = colIndex === 'first_col_text', _obj ) ],attrs:{"text-params":col}})],1)}),0)}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }