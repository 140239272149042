<template>
  <div :class="$style.authorContainer" data-luko-tracking="ArticleAuthor">
    <PImage :class="$style.image" :image-object="authorInfos.picture" />
    <div :class="$style.authorInfos">
      <p :class="$style.name">
        {{ namePreText }}
        <span>
          {{ authorInfos.name }}
        </span>
      </p>
      <p :class="$style.lastUpdate">
        {{ datePreText }}
        <span>
          {{ new Date(lastPublication).toLocaleDateString('fr-FR') }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import PImage from '~/components/PImage'

export default {
  name: 'Author',

  components: { PImage },

  props: {
    authorInfos: {
      type: Object,
      default: () => ({}),
    },
    lastPublication: {
      type: String,
      default: '',
    },
  },

  computed: {
    namePreText() {
      if (this.$i18n.locale === 'fr') return `Par`
      return `By`
    },
    datePreText() {
      if (this.$i18n.locale === 'fr') return `Mis à jour le`
      return `Updated on`
    },
  },
}
</script>

<style lang="scss" module>
.authorContainer {
  display: flex;
  margin-bottom: 40px;

  @include above(medium) {
    margin-bottom: 180px;
  }
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  object-fit: cover;
}

.authorInfos {
  margin-left: 16px;
}

.name {
}

.lastUpdate {
  @include typo-sub-text;
  color: $gray-400;
  span {
    color: $gray-1000;
  }
}
</style>
