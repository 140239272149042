// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Highlight__highlightContainer___2_y_x{margin:24px 12px 0;padding:24px;border-radius:8px;color:#2d50e6;background-color:#ebf5ff;box-shadow:0 4px 8px #eae9e3}.Highlight__highlightContainer___2_y_x a{color:inherit;text-decoration:underline!important}.Highlight__danger___1BjwB{color:#e2777f;background-color:#ffe8ea}.Highlight__warning___2ktPE{color:#e7976a;background-color:#ffede3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"highlightContainer": "Highlight__highlightContainer___2_y_x",
	"danger": "Highlight__danger___1BjwB",
	"warning": "Highlight__warning___2ktPE"
};
module.exports = ___CSS_LOADER_EXPORT___;
