// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TweetsList__tweet___2zMG1{margin-top:24px}.TweetsList__tweet___2zMG1:first-child{margin-top:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"tweet": "TweetsList__tweet___2zMG1"
};
module.exports = ___CSS_LOADER_EXPORT___;
