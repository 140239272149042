<template>
  <div :class="$style.tableContainer" data-luko-tracking="ArticleTable">
    <Title
      is-headline
      :title-params="primary.table_title"
      :class="$style.title"
    />
    <div :class="$style.tableWrapper">
      <table
        :class="[
          [$style.table],
          { [$style.firstCentered]: primary.first_col_text_centered },
          { [$style.secondCentered]: primary.second_col_text_centered },
          { [$style.thirdCentered]: primary.third_col_text_centered },
          { [$style.fourthCentered]: primary.fourth_col_text_centered },
        ]"
      >
        <thead>
          <tr :class="$style.tableHeader">
            <th>
              {{ primary.first_col_title }}
            </th>
            <th>
              {{ primary.second_col_title }}
            </th>
            <th>
              {{ primary.third_col_title }}
            </th>
            <th>
              {{ primary.fourth_col_title }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            :class="$style.tableRow"
          >
            <td v-for="(col, colIndex) in item" :key="colIndex">
              <CheckIcon
                v-if="col[0] && col[0].text === 'true'"
                :class="$style.icon"
                height="16px"
                class="icon-mint-500"
              />

              <CrossIcon
                v-else-if="col[0] && col[0].text === 'false'"
                :class="$style.icon"
                height="12px"
                class="icon-terracota-500"
              />

              <RichText
                v-else
                :class="[
                  [$style.richtext],
                  { [$style.firstCol]: colIndex === 'first_col_text' },
                ]"
                :text-params="col"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CheckIcon from 'LkIcons/Check.vue'
import CrossIcon from 'LkIcons/Cross.vue'

import RichText from '~/components/RichText'
import Title from '~/components/Title'

export default {
  name: 'ArticleTable',
  components: {
    RichText,
    Title,
    CheckIcon,
    CrossIcon,
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
      items: this.slice.items,
    }
  },
}
</script>

<style lang="scss" module>
.title {
  margin-top: 40px;
}

.tableWrapper {
  padding-bottom: 16px;
  overflow-x: auto;
}

.table {
  width: max-content;
  max-width: 840px;
  margin-top: 32px;

  word-break: break-word;

  td,
  th {
    width: fit-content;
    max-width: 254px;
    padding-right: 40px;
    padding-bottom: 24px;

    text-align: left;

    &:last-child {
      padding-right: 0;
    }
  }
}

.firstCentered th:nth-child(1),
.firstCentered td:nth-child(1) {
  text-align: center;
}

.secondCentered th:nth-child(2),
.secondCentered td:nth-child(2) {
  text-align: center;
}

.thirdCentered th:nth-child(3),
.thirdCentered td:nth-child(3) {
  text-align: center;
}

.fourthCentered th:nth-child(4),
.fourthCentered td:nth-child(4) {
  text-align: center;
}

.tableHeader {
  @include typo-caption;
  width: fit-content;

  color: $gray-500;
}

.tableRow {
  @include typo-sub-text;
  width: fit-content;

  color: $gray-700;
  &:last-child td {
    padding-bottom: 0;
  }

  strong {
    color: $gray-1000;
    font-weight: 500;
  }

  em {
    color: $terracota-500;
  }
}

.icon {
  display: block;
  margin: 0 auto;
}
</style>
