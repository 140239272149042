<template>
  <article :class="$style.container" data-luko-tracking="TwitterTestimonial">
    <div :class="$style.tweet">
      <PLink :link-object="content.link" :class="$style.tweetLink">
        <RichText :text-params="content.content" :class="$style.tweetContent" />
        <div :class="$style.tweetInfos">
          <span :class="$style.profilePicture">
            <PImage
              v-if="content.profile_picture.url"
              :image-object="content.profile_picture"
            />
          </span>
          <RichText :text-params="content.infos" :class="$style.tweetName" />
          <TwitterIcon :class="$style.twitterIcon" />
        </div>
      </PLink>
    </div>
  </article>
</template>

<script>
import TwitterIcon from 'LkIcons/SocialTwitter.vue'

import RichText from '~/components/RichText'
import PImage from '~/components/PImage'
import PLink from '~/components/PLink'

export default {
  name: 'TwitterTestimonial',

  components: {
    RichText,
    PImage,
    PLink,
    TwitterIcon,
  },

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss" module>
.tweet {
  margin-top: 24px;

  @include above(small) {
    flex-basis: calc(50% - 24px);
    flex-grow: 1;
    min-width: 350px;
    margin-left: 24px;
  }

  @include above(medium) {
    flex-basis: none;
    flex-grow: 0;
    min-width: 0;
    margin-left: 0;

    &:first-child {
      margin-top: 0;
    }
  }
  // workaround for RichText generating additionnal unexpected elements

  & > .tweetInfos,
  & > .tweetContent {
    display: none;
  }
}

.tweetLink {
  display: block;
  padding: 24px;
  border: 2px solid $gray-200;
  border-radius: 8px;

  color: $gray-1000;

  text-decoration: none;

  transition: border-color 0.3s;

  &:hover {
    border: 2px solid $gray-100;
  }

  a {
    color: $bluko-500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tweetInfos {
  display: flex;
  align-items: center;
  margin-top: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
  .profilePicture {
    width: 32px;
    height: 32px;
    border-radius: 24px;
    overflow: hidden;

    background-color: $bluko-100;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.tweetName {
  flex-grow: 1;
  margin-left: 16px;

  color: $gray-500;
}

.twitterIcon {
  path {
    fill: #1da1f2 !important;
  }
}
</style>
