// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleTable__title___3HpRp{margin-top:40px}.ArticleTable__tableWrapper___21mMT{padding-bottom:16px;overflow-x:auto}.ArticleTable__table___3-i8t{width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:840px;margin-top:32px;word-break:break-word}.ArticleTable__table___3-i8t td,.ArticleTable__table___3-i8t th{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;max-width:254px;padding-right:40px;padding-bottom:24px;text-align:left}.ArticleTable__table___3-i8t td:last-child,.ArticleTable__table___3-i8t th:last-child{padding-right:0}.ArticleTable__firstCentered___1ZlrC td:first-child,.ArticleTable__firstCentered___1ZlrC th:first-child,.ArticleTable__fourthCentered___lVn_A td:nth-child(4),.ArticleTable__fourthCentered___lVn_A th:nth-child(4),.ArticleTable__secondCentered___2QCry td:nth-child(2),.ArticleTable__secondCentered___2QCry th:nth-child(2),.ArticleTable__thirdCentered___1RDQX td:nth-child(3),.ArticleTable__thirdCentered___1RDQX th:nth-child(3){text-align:center}.ArticleTable__tableHeader___N34WB{color:inherit;font-size:12px;line-height:16px;color:#96948a}.ArticleTable__tableHeader___N34WB,.ArticleTable__tableRow___1dW9A{font-weight:400;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.ArticleTable__tableRow___1dW9A{color:inherit;font-size:14px;line-height:20px;color:#6b6651}.ArticleTable__tableRow___1dW9A:last-child td{padding-bottom:0}.ArticleTable__tableRow___1dW9A strong{color:#2c2302;font-weight:500}.ArticleTable__tableRow___1dW9A em{color:#d95762}.ArticleTable__icon___1yXE4{display:block;margin:0 auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"title": "ArticleTable__title___3HpRp",
	"tableWrapper": "ArticleTable__tableWrapper___21mMT",
	"table": "ArticleTable__table___3-i8t",
	"firstCentered": "ArticleTable__firstCentered___1ZlrC",
	"fourthCentered": "ArticleTable__fourthCentered___lVn_A",
	"secondCentered": "ArticleTable__secondCentered___2QCry",
	"thirdCentered": "ArticleTable__thirdCentered___1RDQX",
	"tableHeader": "ArticleTable__tableHeader___N34WB",
	"tableRow": "ArticleTable__tableRow___1dW9A",
	"icon": "ArticleTable__icon___1yXE4"
};
module.exports = ___CSS_LOADER_EXPORT___;
