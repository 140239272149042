// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardCta__container___QPCid{display:flex;flex-wrap:wrap;grid-gap:24px;gap:24px;justify-content:space-between;padding-top:80px}.CardCta__card___3QSk1{display:flex;flex-direction:column;flex-grow:1;width:46%;min-width:260px;border-radius:8px;background-color:#fff1e9}@media (max-width:539px){.CardCta__card___3QSk1{width:100%}}.CardCta__card___3QSk1.CardCta__isOdd___12nS7{flex-direction:row;width:100%}@media (max-width:539px){.CardCta__card___3QSk1.CardCta__isOdd___12nS7{flex-direction:column}}.CardCta__image___8T6xX{width:100%;height:120px;border-radius:8px 8px 0 0;-o-object-fit:cover;object-fit:cover;-o-object-position:center center;object-position:center center}.CardCta__image___8T6xX.CardCta__isOdd___12nS7{width:25%;height:100%;border-radius:8px 0 0 8px}@media (max-width:539px){.CardCta__image___8T6xX.CardCta__isOdd___12nS7{width:100%;height:120px;border-radius:8px 8px 0 0}}.CardCta__content___3EnzQ{padding:24px;text-align:center}.CardCta__content___3EnzQ.CardCta__isOdd___12nS7{width:75%;text-align:left}@media (max-width:539px){.CardCta__content___3EnzQ.CardCta__isOdd___12nS7{width:100%;text-align:center}}.CardCta__button___2QpkH{margin-top:24px}.CardCta__button___2QpkH:hover{text-decoration:none!important}.CardCta__title___1AUpp{color:inherit;font-weight:500;font-size:20px;line-height:32px;margin-bottom:8px;color:#2c2302}.CardCta__title___1AUpp strong{color:#2d50e6}.CardCta__title___1AUpp.CardCta__isOdd___12nS7{margin-bottom:4px}@media (max-width:719px){.CardCta__title___1AUpp.CardCta__isOdd___12nS7{margin-bottom:8px}}.CardCta__description___3zsku{color:#6b6651}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "CardCta__container___QPCid",
	"card": "CardCta__card___3QSk1",
	"isOdd": "CardCta__isOdd___12nS7",
	"image": "CardCta__image___8T6xX",
	"content": "CardCta__content___3EnzQ",
	"button": "CardCta__button___2QpkH",
	"title": "CardCta__title___1AUpp",
	"description": "CardCta__description___3zsku"
};
module.exports = ___CSS_LOADER_EXPORT___;
