<template>
  <section data-luko-tracking="ArticleLink">
    <div
      v-if="primary.link_type && primary.link"
      :class="$style.buttonContainer"
    >
      <LkButton
        :href="getFormatedLink(primary.link)"
        :class="$style.button"
        :target="primary.link.target"
      >
        {{ primary.text }}
      </LkButton>
    </div>
    <PLink
      v-else
      :class="$style.link"
      :link-object="primary.link"
      is-highlight
      color="bluko"
      data-luko-tracking="ArticleLink"
    >
      {{ primary.text }}
    </PLink>
  </section>
</template>

<script>
import PLink from '~/components/PLink'

import { getFormatedLink } from '@/mixins/getFormatedLink'

export default {
  name: 'ArticleLink',
  components: { PLink },
  mixins: [getFormatedLink],

  props: {
    slice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      primary: this.slice.primary,
    }
  },
}
</script>

<style lang="scss" module>
.link {
  display: block;
  margin-top: 24px;
}
.buttonContainer {
  padding-top: 40px;

  text-align: center;
}
.button {
  &:hover {
    text-decoration: none !important;
  }
}
</style>
