<template>
  <div
    id="summaryContainer"
    :class="$style.summaryContainer"
    data-luko-tracking="Summary"
  >
    <div :class="$style.stickyContainer">
      <section :class="$style.bar">
        <div :class="$style.progressBar">
          <span
            :class="$style.progression"
            :style="`height: ${barProgression}px`"
          ></span>
        </div>

        <ul :class="$style.summaryList">
          <li
            v-for="(title, titleIndex) in titlesArray"
            :id="`summaryTitle${titleIndex}`"
            :key="titleIndex"
            :class="{
              [$style.activeTitle]: title === titlesProgression[titleIndex],
            }"
          >
            <a :href="`#${titleId(title)}`">
              {{ title }}
            </a>
          </li>
        </ul>
      </section>

      <div v-if="stickyCta.length">
        <StickyCta
          v-for="(slice, index) in stickyCta"
          :key="index"
          :slice="slice"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StickyCta from '../StickyCta'

export default {
  name: 'Summary',

  components: {
    StickyCta,
  },

  props: {
    titlesArray: {
      type: Array,
      default: () => [],
    },
    titlesProgression: {
      type: Array,
      default: () => [],
    },
    stickyCta: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    barProgression() {
      if (this.titlesProgression.length === 0) return 0
      const progressionElem = document.getElementById(
        `summaryTitle${this.titlesProgression.length - 1}`
      )
      if (this.titlesProgression.length === this.titlesArray.length)
        return progressionElem.offsetTop + progressionElem.clientHeight

      return progressionElem.offsetTop + progressionElem.clientHeight / 2
    },
  },

  beforeMount() {
    const element = document?.getElementById('mainRoot')
    if (element) element.style.overflowX = 'initial' // So the Summary can be sticky
  },

  methods: {
    titleId(title) {
      const formatedTitle = title
        .split(' ')
        .map((e) => e.split(''))
        .filter((e) => e.length > 1)
        .map((e, i, a) => e.join(''))
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036F]/g, '')
      return formatedTitle
    },
  },
}
</script>

<style lang="scss" module>
.summaryContainer {
  width: 100%;

  height: 100%;
}
.stickyContainer {
  position: sticky;
  top: 128px;
}
.bar {
  position: relative;
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;

  width: 8px;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;

  background-color: $gray-100;
}

.progression {
  display: block;
  width: 100%;

  background-color: $bluko-500;

  transition: height 0.3s;
}

.summaryList {
  padding-left: 24px;
  li a {
    display: block;
    padding: 12px 0;

    color: $gray-700;
    text-decoration: none;
    @include typo-body;

    &:hover {
      text-decoration: underline;
    }
  }
}

li.activeTitle a {
  color: $bluko-500;
}
</style>
