// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/advice/banner-cta-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BannerCta__container___2_BRg{display:flex;min-height:176px;margin-top:80px;padding:24px 0 24px 32px;border-radius:8px;background-color:#fff1e9;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:100% 100%;background-size:260px auto}@media (max-width:719px){.BannerCta__container___2_BRg{padding:24px;background-image:none}}.BannerCta__text___2acYi{max-width:65%}@media (max-width:719px){.BannerCta__text___2acYi{max-width:100%}}.BannerCta__title___1CtMP{color:inherit;font-weight:500;font-size:20px;line-height:32px;color:#2d50e6}.BannerCta__title___1CtMP h3{margin-bottom:0}.BannerCta__desciprion___2PhQ4{color:#2c2302}.BannerCta__button___1mGJv{margin-top:16px}.BannerCta__button___1mGJv:hover{text-decoration:none!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"container": "BannerCta__container___2_BRg",
	"text": "BannerCta__text___2acYi",
	"title": "BannerCta__title___1CtMP",
	"desciprion": "BannerCta__desciprion___2PhQ4",
	"button": "BannerCta__button___1mGJv"
};
module.exports = ___CSS_LOADER_EXPORT___;
