// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArticleImage__imageContainer___9aRJt{width:100%}.ArticleImage__imageContainer___9aRJt img{width:100%;border-radius:8px}.ArticleImage__imageContainer___9aRJt figcaption{margin-top:8px;color:inherit;font-weight:400;font-size:14px;line-height:20px;color:#aaa8a1;font-style:italic;text-align:center}.ArticleImage__imageContainer___9aRJt figcaption a{color:#2d50e6;text-decoration:none}.ArticleImage__imageContainer___9aRJt figcaption a:hover{text-decoration:underline}.ArticleImage__hasMobileImage___2x0LI .ArticleImage__desktopImage___3plmz{display:none}@media (min-width:720px){.ArticleImage__hasMobileImage___2x0LI .ArticleImage__desktopImage___3plmz{display:block}}@media (min-width:720px){.ArticleImage__hasMobileImage___2x0LI .ArticleImage__mobileImage___3sLFS{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"imageContainer": "ArticleImage__imageContainer___9aRJt",
	"hasMobileImage": "ArticleImage__hasMobileImage___2x0LI",
	"desktopImage": "ArticleImage__desktopImage___3plmz",
	"mobileImage": "ArticleImage__mobileImage___3sLFS"
};
module.exports = ___CSS_LOADER_EXPORT___;
