// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SecondTitle__title___1bPwb{color:inherit;font-weight:500;font-size:32px;line-height:48px;padding-top:128px;color:#2c2302;-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xsmall": "540px",
	"small": "720px",
	"medium": "960px",
	"large": "1280px",
	"title": "SecondTitle__title___1bPwb"
};
module.exports = ___CSS_LOADER_EXPORT___;
